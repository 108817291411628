<template>
  <v-container fluid class="leaflet-details mx-0 py-0 grey lighten-1"
    ><div v-if="leaflet" class="white py-5 px-2 d-flex align-center">
      <div class="flex-grow-1">
        <div class="text-body-0 font-weight-semibold">{{ leaflet.name }}</div>
        <div>
          Dal {{ $dayjs(leaflet.fromDate).format("DD MMMM") }} al
          {{ $dayjs(leaflet.toDate).format("DD MMMM YYYY") }}
        </div>
      </div>
      <div class="flex-shrink-0">
        <v-btn icon @click="toggleZoom"
          ><v-icon color="default">{{
            pageZoom ? "$zoomOut" : "$zoom"
          }}</v-icon></v-btn
        ><v-btn icon @click="toogleFullscreen"
          ><v-icon color="default">$fullscreen</v-icon></v-btn
        >
        <v-btn icon @click="downloadLeaflet(leaflet)"
          ><v-icon color="default">$download</v-icon></v-btn
        >
        <v-btn icon @click="share()"
          ><v-icon color="default">$share</v-icon></v-btn
        >
      </div>
    </div>
    <div ref="leafletViewerWraper">
      <div
        class="leafletViewer"
        v-if="leaflet"
        :class="{
          fullscreen: leafletFullscreen,
          iosFullscreen: isIOSFullscreen
        }"
      >
        <v-btn
          v-if="leafletFullscreen || isIOSFullscreen"
          fab
          color="white"
          @click="toogleFullscreen"
          class="exit-fullsreen"
          ><v-icon>$close</v-icon></v-btn
        >
        <swiper
          :options="swiperOptions"
          ref="swiper"
          :slides-per-view="'auto'"
          @doubleTap="handleZoom"
          @zoomChange="handleZoom"
        >
          <swiper-slide
            v-for="page in leaflet.leafletPage"
            :key="page.leafletPageId"
          >
            <div class="leaflet-page-container">
              <div
                class="imgage-container swiper-zoom-container py-2 py-md-6"
                :style="{
                  maxHeight: swiperHeight,
                  height: fullscreen ? swiperHeight : 'auto'
                }"
              >
                <img
                  class="image"
                  :src="`${leaflet.baseLocation}${page.imageLocation}`"
                  :alt="`Volantino pagina ${page.pageNumber}`"
                />
              </div>
            </div>
          </swiper-slide>
          <!-- <div
            id="swiper-pagination"
            class="swiper-pagination"
            slot="pagination"
          ></div> -->
        </swiper>
      </div>
    </div></v-container
  >
</template>
<style lang="scss">
/*
what it does? It disables scroll on ios safari/chrome when
flyer is in fullscreen
*/
.body-fixed-position {
  position: fixed;
}
/*
sets the flyer in fullscreen mode in ios safari/chrome
*/
.iosFullscreen {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  width: 100vw;
  height: 100vh;
  background-color: black;
  .disable-zoom-on-safari {
    touch-action: unset !important;
  }
  .swiper-pagination,
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
  .draggable {
    pointer-events: none;
  }
  .swiper-container {
    height: 100%;
  }
  .leaflet-page-container {
    margin-top: 15px;
  }
}
.leaflet-details {
  min-height: calc(100vh - 142px);
  .leafletViewer {
    position: relative;
    .leaflet-page-container {
      .imgage-container {
        padding: 0 8px;
        .image {
          width: 100%;
          border-radius: 8px;
        }
      }
      .swiper-pagination {
        position: absolute;
        top: 16px;
        left: 60px;
        background-color: black;
        font-size: 12px;
        color: #fff;
        border-radius: 4px;
        height: 35px;
        width: fit-content;
        padding: 0px 5px;
      }
    }
  }
  .leafletViewer.fullscreen {
    height: 100%;
    .swiper-container {
      height: 100%;
    }
    .leaflet-page-container {
      margin-top: 15px;
    }
  }
  .exit-fullsreen {
    position: absolute;
    opacity: 0.8;
    right: 5px;
    top: 5px;
    z-index: 2;
  }
}
</style>
<script>
import LeafletService from "@/commons/service/leafletService";
import downloadDocumentMixin from "~/mixins/downloadDocument";

const header = { fullscreenHeader: 97, mobileHeader: 280, desktopHeader: 300 };

export default {
  name: "LeafletDetails",
  mixins: [downloadDocumentMixin],
  data() {
    return {
      leafletId: null,
      leaflet: null,
      fullscreen: false,
      swiperHeight: "auto",
      leafletFullscreen: false,
      isIOSFullscreen: false,
      pageZoom: false,
      shareLoading: false,
      swiperOptions: {
        slidesPerView: 1,
        allowTouchMove: true,
        zoom: true,
        centeredSlides: false,
        slidesPerGroupSkip: 1,
        grabCursor: true,

        keyboard: {
          enabled: true
        },
        breakpoints: {
          769: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            pagination: {
              el: "#swiper-pagination",
              type: "custom",
              renderCustom: function(swiper) {
                const activeIndex = swiper.activeIndex + 1;
                const nextIndex = activeIndex == 1 ? null : activeIndex + 1;
                return (
                  "<span class='pagination-value'>Pagina <b>" +
                  activeIndex +
                  (nextIndex ? ", " + nextIndex : "") +
                  "</b> di " +
                  swiper.slides.length +
                  "</span>"
                );
              }
            }
          }
        },
        pagination: {
          el: "#swiper-pagination",
          type: "custom",
          renderCustom: function(swiper, current, total) {
            return (
              "<span class='pagination-value'>Pagina <b>" +
              current +
              "</b> di " +
              total +
              "</span>"
            );
          }
        },
        navigation: {
          nextEl: "#leaflet-button-next",
          prevEl: "#leaflet-button-prev"
        }
      }
    };
  },
  methods: {
    setLeaflet(id) {
      LeafletService.getLeafletById(id).then(data => {
        this.leaflet = data;
        this.leafletId = id;
        this.$route.meta.title = this.leaflet.name;
        global.EventBus.$emit("resetNavbar");
      });
    },
    toogleFullscreen() {
      const _this = this;
      // /**
      //  * Detect if the user is using Safari on mobile
      //  */
      const ua = window.navigator.userAgent;
      const iOS = !!ua.match(/iP(ad|hone)/i);
      const webkit = !!ua.match(/WebKit/i);
      const isChrome = !!ua.match(/Chrome/i);
      const isIOSSafariOrChrome = (iOS && webkit) || (iOS && isChrome);
      /**
       * Open LeafletModal if the user uses iOS APP or Safari mobile.
       * Open LeafletModal if the user uses iOS APP or Safari/chrome mobile.
       * If not open LeafletViewer
       */
      if (
        isIOSSafariOrChrome ||
        (window.cordova && window.cordova.platformId.toLowerCase() === "ios")
      ) {
        /**
         * Show flyer in fullscreen mode
         */
        this.isIOSFullscreen = !this.isIOSFullscreen;
      } else {
        if (_this.leafletFullscreen) {
          document.exitFullscreen().then(function() {
            if (_this.$vuetify.breakpoint.mdAndUp) {
              _this.swiperHeight =
                window.innerHeight - header.desktopHeader + "px";
            } else {
              _this.swiperHeight =
                window.innerHeight - header.mobileHeader + "px";
            }
          });
        } else {
          let elem = this.$refs.leafletViewerWraper;
          var methodToBeInvoked =
            elem.requestFullscreen ||
            elem.webkitRequestFullScreen ||
            elem["mozRequestFullscreen"] ||
            elem["msRequestFullscreen"];
          let _this = this;
          if (methodToBeInvoked)
            methodToBeInvoked.call(elem).then(function() {
              _this.swiperHeight =
                window.screen.height - header.fullscreenHeader + "px";
            });
        }
      }
    },
    exitHandler() {
      let attr =
        document.fullscreen === true ||
        document.webkitIsFullScreen === true ||
        document.mozFullScreen === true ||
        document.msFullscreenElement === true;

      if (attr === false) {
        this.leafletFullscreen = false;
      } else if (attr === true) {
        this.leafletFullscreen = true;
      }
    },
    toggleZoom() {
      const swiper = this.$refs.swiper.$swiper;
      swiper.zoom.toggle();
      this.pageZoom = swiper.zoom.scale == 1 ? false : true;
    },
    handleZoom() {
      const swiper = this.$refs.swiper.$swiper;
      this.pageZoom = swiper.zoom.scale == 1 ? false : true;
    },
    async downloadLeaflet(leaflet) {
      global.EventBus.$emit("success", {
        message: global.vm.$t("leaflet.downloadStarted")
      });
      await this.handleReceiptDownload(
        leaflet.baseLocation + leaflet.location,
        "Volantino_" + leaflet.name
      );
    },
    async share() {
      this.shareLoading = true;
      const pdfUrl = encodeURI(
        this.leaflet.baseLocation + this.leaflet.location
      );
      const fileName = "Volantino_" + this.leaflet.name;

      try {
        const response = await fetch(pdfUrl);
        const blob = await response.blob();

        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function() {
          const base64data = reader.result;
          console.log(base64data);
          window.plugins.socialsharing.share(null, fileName, base64data, null);
        };
      } catch (error) {
        console.error("Error downloading file:", error);
      } finally {
        this.shareLoading = false;
      }
    }
  },
  async created() {
    await this.setLeaflet(this.$route.params.leafletId);
  },
  mounted() {
    let elem = this.$refs.leafletViewerWraper;
    elem.addEventListener("fullscreenchange", this.exitHandler, false);
    elem.addEventListener("mozfullscreenchange", this.exitHandler, false);
    elem.addEventListener("MSFullscreenChange", this.exitHandler, false);
    elem.addEventListener("webkitfullscreenchange", this.exitHandler, false);
  },
  unmouted() {
    let elem = this.$refs.leafletViewerWraper;
    elem.removeEventListener("fullscreenchange", this.exitHandler);
    elem.removeEventListener("mozfullscreenchange", this.exitHandler);
    elem.removeEventListener("MSFullscreenChange", this.exitHandler);
    elem.removeEventListener("webkitfullscreenchange", this.exitHandler);
  },
  watch: {
    async $route(to) {
      if (to.params.leafletId != this.leafletId) {
        this.slug = to.params.slug;
        this.setProduct(this.$route.params.leafletId);
      }
    },
    isIOSFullscreen(newValue) {
      if (newValue) {
        document.body.classList.add("body-fixed-position");
      } else {
        document.body.classList.remove("body-fixed-position");
      }
    }
  }
};
</script>
